/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 15:40:48
 */

import Router from 'vue-router';
import groupRouter from './grouprouter';
import DataRouterView from './router-view.vue'
// import hotelRouter from './hotelrouter';

// const RouterView = {
//   template: '<div><router-view></router-view></div>',
// };

const routes = [
  {
    path: '/data-analysis',
    component: DataRouterView,
    children: [...groupRouter],
  },
];

export default new Router({
  mode: 'history',
  routes,
});
