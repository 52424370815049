import apiMapping from './apilist';

const {
  showToast, fetch, download, urlToDownload,
} = window.$axios;

function handleUrlFetch(data) {
  return fetch({
    ...data,
    url: apiMapping[data.url],
  });
}

function getUrlInNoFetch(url) {
  return apiMapping[url];
}

export default {
  showToast,
  fetch: handleUrlFetch,
  download,
  urlToDownload,
  getUrlInNoFetch,
};
