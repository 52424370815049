<template>
  <div>
    <el-button
      :type="type"
      :icon="icon"
      :native-type="nativeType"
      :size="size"
      :loading="loading"
      :disabled="permissionDisable"
      :plain="plain"
      :autofocus="autofocus"
      :round="round"
      :circle="circle"
      @click="handleClick"
      v-if="permissionType === 'button'"
    >
      {{ title }}
    </el-button>
    <el-switch
      v-else-if="permissionType === 'switch'"
      v-model="switchValue"
      :active-text="activeText"
      @change="statusChange"
      :inactive-text="inactiveText"
      :disabled="permissionDisable || disabled"
    />
    <div v-else-if="permissionType === 'empty'" @click="handleClick">
      {{ title }}
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "PermissionButton",
  props: {
    permissionCode: {
      type: String,
      default: "",
    },
    permissionType: {
      type: String,
      default: "button",
    },
    title: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    nativeType: {
      type: String,
      default: "button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },

    // switch属性
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    width: {
      type: Number,
      default: 40,
    },
    activeIconClass: {
      type: String,
      default: "",
    },
    inactiveIconClass: {
      type: String,
      default: "",
    },
    activeText: {
      type: String,
      default: "",
    },
    inactiveText: {
      type: String,
      default: "",
    },
    activeColor: {
      type: String,
      default: "",
    },
    inactiveColor: {
      type: String,
      default: "",
    },
    activeValue: {
      type: [Boolean, String, Number],
      default: 1,
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    validateEvent: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      switchValue: "",
      permissionDisable: true,
      tabList: [],
      isShow: true,
    };
  },
  methods: {
    handleClick() {
      if (this.permissionDisable) {
        this.$httpUtilDataAnalysis.showToast("text", "没有操作权限");
      } else {
        this.$emit("handleClick");
      }
    },
    getButtonIsShow() {
      const perCodeList = localStorage.getItem("uiPermissionCodes").split(",");
      this.permissionDisable = !(
        perCodeList.indexOf(this.permissionCode) >= 0 ||
        this.permissionCode === "all"
      );
    },
    statusChange() {
      this.switchValue = !this.switchValue;
      this.$emit("statusChange");
    },
  },
  watch: {
    permissionDisable(value, oldValue) {
      if (value !== oldValue) {
        this.permissionDisable = value;
        this.getButtonIsShow();
      }
    },
    value(value, oldValue) {
      if (value !== oldValue) {
        this.value = value;
        this.getButtonIsShow();
        this.switchValue = value === this.activeValue;
      }
    },
  },
  created() {
    this.getButtonIsShow();
    if (this.permissionType === "switch") {
      this.switchValue = this.value === this.activeValue;
    }
  },
  filters: {},
  components: {},
};
</script>

<style scoped></style>
