<template>
  <div :id="echartsContainerId"
       :style="`width: ${width}; height: ${height}`" />
</template>
<script>

import echarts from 'echarts';

export default {
  props: {
    width: {
      require: true,
      type: String,
      default: '800px',
    },
    height: {
      require: true,
      type: String,
      default: '400px',
    },
    containerId: {
      require: false,
      type: String,
      default: '',
    },
    // 是否异步获取数据，如果非异步获取，直接将展示数据传到options即可，dataset不用传
    // 如果异步获取数据，将options和dataset分开，先传入options展示基础样子之后有数据时展示数据，在初始化时会展示loading，dataset数据发生变更后会隐藏loading
    isAsyncData: {
      require: true,
      type: Boolean,
      default: true,
    },
    options: {
      require: true,
      type: Object,
      default: null,
    },
    dataset: {
      require: false,
      type: Object,
      default: null,
    },
  },
  name: 'LcEcharts',
  data() {
    return {
      echartsContainerId: this.containerId || `chart${new Date().valueOf()}${Math.floor(Math.random() * 1000)}`,
      myChartInstance: null,
      isShowLoading: false,
    };
  },
  mounted() {
    const myChart = echarts.init(document.getElementById(this.echartsContainerId));

    // 指定图表的配置项和数据显示图表
    if (this.options) {
      myChart.setOption(this.options);
    }

    // 如果是异步显示数据
    if (this.isAsyncData) {
      myChart.showLoading();
      this.isShowLoading = true;
    }

    this.myChartInstance = myChart;
  },

  watch: {
    options(newData) {
      this.myChartInstance.setOption(newData, true);
    },
    dataset(newData) {
      this.myChartInstance.setOption(newData, true);
      if (this.isShowLoading) {
        this.myChartInstance.hideLoading();
        this.isShowLoading = false;
      }
    },
  },
};
</script>
